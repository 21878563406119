<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-row>
                            <b-col lg="12" sm="12">
                                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                    <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                        <b-row>
                                            <b-col lg="6" md="6" sm="6" xs="6">
                                                <ValidationProvider name="Circular Type" vid="circular_type" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="circular_type"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('dae_config.circular_type') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="projectSetupData.circular_type"
                                                        :options="subsidyCircularTypeList"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        id="fiscal_year_id"
                                                        >
                                                        <template v-slot:first>
                                                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="id">
                                                <ValidationProvider name="Project ID" vid="project_id">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="project_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('bsri_config.project_id') }}
                                                        </template>
                                                        <b-form-input
                                                        readonly
                                                        id="project_id"
                                                        v-model="projectSetupData.project_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="Project Name" vid="project_name" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="project_name"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('bsri_config.project_name_en') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                        id="project_name"
                                                        v-model="projectSetupData.project_name"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="Project Name (Bn)" vid="project_name_bn" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="project_name_bn"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_config.project_name_bn')}}<span class="text-danger">*</span>
                                                    </template>
                                                        <b-form-input
                                                        id="project_name_bn"
                                                        v-model="projectSetupData.project_name_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="Description" vid="description">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="description"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_config.project_desc_en')}}
                                                    </template>
                                                    <b-form-textarea
                                                      id="description"
                                                      v-model="projectSetupData.description"
                                                      rows="1"
                                                      max-rows="2"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-textarea>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="Description" vid="description_bn">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="description_bn"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_config.project_desc_bn')}}
                                                    </template>
                                                    <b-form-textarea
                                                      id="description"
                                                      v-model="projectSetupData.description_bn"
                                                      rows="1"
                                                      max-rows="2"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-textarea>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="Start Date" vid="start_date" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="start_date"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_config.project_duration_from')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        id="datepicker"
                                                        v-model="projectSetupData.start_date"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <ValidationProvider name="End Date" vid="end_date" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="end_date"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('bsri_config.project_duration_to')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    id="datepicker"
                                                    v-model="projectSetupData.end_date"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <div class="col text-right">
                                                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                    &nbsp;
                                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                                </div>
                                            </b-col>
                                        </b-row>
                                    </b-form>
                                </ValidationObserver>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { projectSetupStore, projectSetupUpdate } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            const tmp = this.getEditingData()
            this.projectSetupData = tmp
        }
    },
    data () {
        return {
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            projectSetupData: {
                circular_type: 0,
                project_id: '',
                project_name: '',
                project_name_bn: '',
                description: '',
                description_bn: '',
                start_date: '',
                end_date: '',
                cul_method_id: 0,
                crop_id: 0
            }
        }
    },
    watch: {
    },
    mounted () {
        core.index()
        flatpickr('#datepicker', {})
    },
    computed: {
        subsidyCircularTypeList: function () {
            return this.$store.state.incentiveGrant.commonObj.subsidyCircularTypeList
        },
        cultivationMethodList: function () {
            const methodList = this.$store.state.incentiveGrant.commonObj.cultivationMethodList
            return methodList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        cropList: function () {
            const cropList = this.$store.state.incentiveGrant.commonObj.cropList
            return cropList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        }
    },
    methods: {
        getEditingData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async saveData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }

            if (this.id) {
                result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${projectSetupUpdate}/${this.id}`, this.projectSetupData)
            } else {
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, projectSetupStore, this.projectSetupData)
            }

            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)

            if (result.success) {
                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        }
    }
}
</script>
