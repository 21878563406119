
<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('bsri_config.project_setup') }}</h4>
        </template>
        <template v-slot:body>
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <ValidationProvider name="Sale Date" vid="sell_date" rules="required">
                <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="sell_date"
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  {{$t('globalTrans.from_date')}} <span class="text-danger">*</span>
                </template>
                <flat-pickr class="form-control"
                v-model="search.start_date"
                :placeholder="$t('globalTrans.select_date')"
                :state="errors[0] ? false : (valid ? true : null)"
                ></flat-pickr>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <ValidationProvider name="Sale Date" vid="sell_date" rules="required">
                  <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="sell_date"
                  slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                    {{$t('globalTrans.to_date')}} <span class="text-danger">*</span>
                  </template>
                  <flat-pickr class="form-control"
                  v-model="search.end_date"
                  :placeholder="$t('globalTrans.select_date')"
                  :state="errors[0] ? false : (valid ? true : null)"
                  ></flat-pickr>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                  </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <ValidationProvider name="Sale Date" vid="item_id">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="item_id"
                    slot-scope="{ errors }"
                >
                  <template v-slot:label>
                    {{$t('bsri_config.project_name')}}
                  </template>
                  <b-form-select
                    plain
                    v-model="search.project_name_id"
                    :options="projectList"
                    id="item_id"
                    >
                    <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                </b-form-select>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
            </b-col>
          </b-row>
        </template>
    </iq-card>
    <b-row>
        <b-col md="12">
            <iq-card>
                <template v-slot:headerTitle>
                    <h4 class="card-title">{{ $t('bsri_config.project_setup') }} {{ $t('globalTrans.list') }}</h4>
                </template>
                <template v-slot:headerAction>
                    <b-button class="btn btn_add_new" v-b-modal.modal-4 @click="resetId">
                      <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                    </b-button>
                </template>
                <template v-slot:body>
                    <b-overlay :show="loadingState">
                        <b-row>
                            <b-col md="12" class="table-responsive">
                                <b-table thead-class="table_head" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                    <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                    </template>
                                    <template v-slot:cell(project_id)="data">
                                        {{ $n(data.item.project_id, { useGrouping: false }) }}
                                    </template>
                                    <template v-slot:cell(status)="data">
                                        <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                        <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                    </template>
                                    <template v-slot:cell(action)="data">
                                     <!--  <b-button class="mr-1" title="View Complain" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button> -->
                                        <!-- <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                        <b-button :variant="data.item.status === 1 ? ' iq-bg-success' : '  iq-bg-danger'" size="sm" @click="remove(data.item)">
                                            <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                                        </b-button> -->
                                        <a href="javascript:" title="Edit" class="btn_table_action table_action_edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                                        <a href="javascript:" :title="data.item.status === 1 ? 'Active' : 'Inactive'" class="btn_table_action" :class="data.item.status === 1 ? ' table_action_view' : ' table_action_toggle'" @click="remove(data.item)">
                                            <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                                        </a>
                                    </template>
                                </b-table>
                                <b-pagination
                                    align="center"
                                    v-model="pagination.currentPage"
                                    :per-page="pagination.perPage"
                                    :total-rows="pagination.totalRows"
                                    @input="searchData"
                                    />
                            </b-col>
                        </b-row>
                    </b-overlay>
                </template>
            </iq-card>
        </b-col>
    </b-row>
    <b-modal id="modal-5" size="lg" :title="$t('bsri_config.project_setup')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <Details :item="item" :key="item"></Details>
    </b-modal>
    <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form :id="editItemId" :key="editItemId"/>
    </b-modal>
  </b-container>
</template>
<script>
import Details from './Details'
import Form from './Form'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { projectSetupList, projectSetupStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, Details
  },
  data () {
    return {
      search: {
        project_name_id: 0,
        start_date: '',
        end_date: '',
        status: 0
      },
      rows: [],
      item: '',
      statusOption: [
        { value: 1, text: 'Active' },
        { value: 2, text: 'InActive' }
      ]
    }
  },
  mounted () {
    flatpickr('#datepicker', {})
  },
  computed: {
    projectList: function () {
      const cropList = this.$store.state.incentiveGrant.commonObj.projectList
      return cropList.map(item => {
          if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
          } else {
              return { value: item.value, text: item.text_en }
          }
      })
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('bsri_config.project_setup') + ' ' + this.$t('globalTrans.entry') : this.$t('bsri_config.project_setup') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('dae_config.circular_type'), class: 'text-center' },
          { label: this.$t('bsri_config.project_id'), class: 'text-center' },
          { label: this.$t('bsri_config.project_name'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'circular_type_name_bn' },
          { key: 'project_id' },
          { key: 'project_name_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'circular_type_name' },
          { key: 'project_id' },
          { key: 'project_name' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.item = item
    },
    remove (item) {
      this.changeStatus(incentiveGrantServiceBaseUrl, projectSetupStatus, item)
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, projectSetupList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
       const subsidyCircularTypeList = this.$store.state.incentiveGrant.commonObj.subsidyCircularTypeList

      const listData = data.map(item => {
        const circularTypeObject = subsidyCircularTypeList.find(typeItem => typeItem.value === item.circular_type)
        const circularTypeData = {
          circular_type_name: circularTypeObject !== 'undefined' ? circularTypeObject.text_en : '',
          circular_type_name_bn: circularTypeObject !== 'undefined' ? circularTypeObject.text_bn : ''
        }
        return Object.assign({}, item, circularTypeData)
      })
      return listData
    }
  }
}
</script>
